import React from "react"
import { Link } from "gatsby"

export default (props) => 
<div id="news-footer">
    <div className="portfolio-inner">
        <Link to="/works">All Work</Link>
        <div id="news-footer-next-prev">
            <Link to={`/works/${props.previous}`}>Previous</Link>
            <Link to={`/works/${props.next}`}>Next</Link>
        </div>
    </div>
</div>
import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/kanebo/kanebo.png"
import kanebo1 from "../../../assets/images/portfolio_items/kanebo/kanebo1.png"
import kanebo2 from "../../../assets/images/portfolio_items/kanebo/kanebo2.png"
import kanebo3 from "../../../assets/images/portfolio_items/kanebo/kanebo3.png"

export default () => 
<div>
    <SEO title={'Kanebo ICON Worldwide | Kanebo Sensai - Facebook Store'} 
    description="A cutting-edge interactive Facebook store to support Kanebo’s European launch of Sensai brand: discover more on ICON's portfolio! "
    canonical={'https://icon-worldwide.com/works/kanebo-sensai'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Kanebo Sensai, ICON Worldwide, Facebook store, portfolio" title="ICON Worldwide portfolio, community engagement, Kanebo Sensai"/>
            <div className="title"><h1>Kanebo Sensai<span></span></h1>
            <h2>Cutting-edge interactive store</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>To support Kanebo’s European launch of the Sensai brand we created an interactive store finder as a Facebook application.</p>
        <div className="portfolio-images" id="long-page">
            <img src={kanebo1} alt=" Kanebo Sensai, ICON Worldwide, Facebook store, portfolio" title="ICON Worldwide portfolio, community engagement, Kanebo Sensai"/>
            <img src={kanebo3} alt=" Kanebo Sensai, ICON Worldwide, Facebook store, portfolio" title="ICON Worldwide portfolio, community engagement, Kanebo Sensai"/>
            <img src={kanebo2} alt=" Kanebo Sensai, ICON Worldwide, Facebook store, portfolio" title="ICON Worldwide portfolio, community engagement, Kanebo Sensai"/>
        </div>
        <WorkFooter previous="womens-football" next="sigma-aldrich"/>
        </div>
    </div>
</div>